import React from 'react';
import { Title } from '../../elements/title/title';

import css from './opening-hours.module.scss';

export const OpeningHours = () => {
    return (
        <>
            <Title as="h2" text="opening hours" />
            <table className={css.root}>
                <tbody>
                    <tr>
                        <td>Weekdays:</td>
                        <td>12:00 (noon) till 02:00 (2am)</td>
                    </tr>
                    <tr>
                        <td>Fridays and Saturdays:</td>
                        <td>12:00 (noon) till 10:00 (10am)</td>
                    </tr>
                    <tr>
                        <td>Sundays:</td>
                        <td>12:00 (noon) till 05:00 (5am)</td>
                    </tr>
                </tbody>
            </table>
        </>
    );
};
