import React, { Suspense } from 'react';
import { BrowserRouter, Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Routes from './routes';
import { isTablet } from 'react-device-detect';
import { SideBar } from './common/features/side-bar/side-bar';
import { CookieConsentFooter } from './common/features/cookie-consent-footer/cookie-consent-footer';
import css from './app.module.scss';

export const isPortraitTablet =
    window.matchMedia('(orientation: portrait)').matches && isTablet;

function App() {
    const history = createBrowserHistory();

    return (
        <Suspense fallback="loading">
            <BrowserRouter>
                <Router history={history}>
                    <main className={css.root}>
                        <SideBar />
                        <section className={css.content}>
                            <Routes />
                        </section>
                        <CookieConsentFooter />
                    </main>
                </Router>
            </BrowserRouter>
        </Suspense>
    );
}

export default App;
