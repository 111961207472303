import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { initGA, logPageView } from '../../scripts/analytics';
import { Paragraph } from '../../elements/paragraph/paragraph';
import css from './cookie-consent-footer.module.scss';

export const CookieConsentFooter: React.FC = () => {
    const [cookiesAccepted, setCookiesAccepted] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (cookiesAccepted) {
            initGA();
        }
    }, [cookiesAccepted]);

    useEffect(() => {
        if (cookiesAccepted) {
            logPageView(location.pathname + location.search);
        }
    }, [location, cookiesAccepted]);

    useEffect(() => {
        const storedCookiesAccepted = localStorage.getItem('cookiesAccepted');
        if (storedCookiesAccepted === 'true') {
            setCookiesAccepted(true);
            initGA();
        }
    }, []);

    const handleCookiesAccept = () => {
        setCookiesAccepted(true);
        localStorage.setItem('cookiesAccepted', 'true');
    };

    return (
        <>
            {!cookiesAccepted && (
                <footer className={css.root}>
                    <Paragraph noMargin>
                        We use cookies to improve your experience and to measure
                        visits to our website
                    </Paragraph>
                    <button
                        className={css.button}
                        onClick={handleCookiesAccept}
                    >
                        Accept cookies
                    </button>
                </footer>
            )}
        </>
    );
};
