import React from 'react';
import { Paragraph } from '../../../common/elements/paragraph/paragraph';

export const LastSaturdays: React.FC = () => {
    const getLastSaturday = (year: number, month: number): Date => {
        const lastDayOfMonth = new Date(year, month + 1, 0);
        const dayOfWeek = lastDayOfMonth.getDay();
        const diff = dayOfWeek >= 6 ? dayOfWeek - 6 : 1 + dayOfWeek; // Calculate difference to last Saturday
        return new Date(year, month, lastDayOfMonth.getDate() - diff);
    };

    const getAllLastSaturdaysFor2025 = () => {
        const lastSaturdays: Date[] = [];
        for (let month = 0; month < 12; month++) {
            lastSaturdays.push(getLastSaturday(2025, month));
        }
        return lastSaturdays;
    };

    const formatDate = (date: Date): string => {
        const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ];
        const month = months[date.getMonth()];
        const day = date.getDate();
        return `${month} ${day}`;
    };

    const lastSaturdays2025 = getAllLastSaturdaysFor2025();

    return (
        <>
            <Paragraph>
                <strong>Next dates:</strong>
                {lastSaturdays2025.map((date, index) => (
                    <React.Fragment key={index}>
                        <br />
                        {formatDate(date)}
                    </React.Fragment>
                ))}
            </Paragraph>
        </>
    );
};
