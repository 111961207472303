import ReactGA from 'react-ga4';

const GA_TRACKING_ID = 'G-89Q1M2XDT5';

export const initGA = () => {
    ReactGA.initialize(GA_TRACKING_ID);
};

export const logPageView = (path: string) => {
    ReactGA.send({ hitType: 'pageview', page: path });
};
