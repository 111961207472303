import React from 'react';
import { Paragraph } from '../../../common/elements/paragraph/paragraph';

export const SecondSundays: React.FC = () => {
    const getSecondSunday = (year: number, month: number): Date => {
        const firstDayOfMonth = new Date(year, month, 1);
        const dayOfWeek = firstDayOfMonth.getDay();
        const diffToSunday = dayOfWeek === 0 ? 0 : 7 - dayOfWeek;
        return new Date(year, month, 1 + diffToSunday + 7);
    };

    const getAllSecondSundaysFor2025 = () => {
        const secondSundays: Date[] = [];
        for (let month = 0; month < 12; month++) {
            secondSundays.push(getSecondSunday(2025, month));
        }
        return secondSundays;
    };

    const formatDate = (date: Date): string => {
        const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ];
        const month = months[date.getMonth()];
        const day = date.getDate();
        return `${month} ${day}`;
    };

    const secondSundays2025 = getAllSecondSundaysFor2025();

    return (
        <>
            <Paragraph>
                <strong>Next dates:</strong>
                {secondSundays2025.map((date, index) => (
                    <React.Fragment key={index}>
                        <br />
                        {formatDate(date)}
                    </React.Fragment>
                ))}
            </Paragraph>
        </>
    );
};
